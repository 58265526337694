import { HashLoader, BeatLoader as UiBeatLoader } from "react-spinners";
import css from "./style.module.scss";

export const Loader = () => {
  return (
    <div className={css["root"]}>
      <HashLoader color="#7ab829" size={90} />
    </div>
  );
};

export const BeatLoader = () => {
  return (
    <div className={css["root"]}>
      <UiBeatLoader color="#7ab829" speedMultiplier={0.5} size={50} />
    </div>
  );
};
